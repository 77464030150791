@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@layer base {
  * {
    @apply font-[Roboto]
  text-white
  }

  h1, h2, h3 {
    @apply font-[Neuzon]
  }

  body {
    background: linear-gradient(180deg, #18173C 0%, #030306 100%);
  }

  .contact-button {
    background: linear-gradient(180deg, #61FDCD 0%, #007954 100%);
  }

  .grid-custom {
    grid-template-rows: 1fr;
  }

  .svg-pic {
    background-image: url(./assets/CSClogotyp\ 3.svg);
    background-repeat: no-repeat;
  }

  .mobile li {
    opacity: .5
  }

    .mobile li:active,
    .mobile li:hover,
    .mobile li:focus {
      opacity: 1;
    }

    .desktop li {
      opacity: .5;
    }
  
    .desktop li:active,
    .desktop li:hover,
    .desktop li:focus {
      opacity: 1 !important;
    }

  .custom-gradient {
    background: linear-gradient(to bottom, rgba(21, 21, 46, 0) 21.17%, #15152E 66.81%);
  }

  .header-gradient {
    background: linear-gradient(to bottom, rgba(21, 21, 46, 0) 63.17%, #181837 100%);
  }

  .image-grid img {
    width: 60px;
  }

  .octy {
    font-family: octynaz, sans-serif;
  }

  .burnaby {
    font-family: burnaby-stencil, sans-serif;
  }
 
  .active {
    color: white;
  }

  .color-correction:hover {
    filter: brightness(75%) sepia(2) saturate(5) hue-rotate(180deg);
  }
}
